.login_container {
    background: url('../assets/login.png') no-repeat center center;;
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
}

.login-body {
    padding-top: 2rem;
}

.login-company {
    width: 16rem;
    height: 4rem;
    margin-left: 2rem;
}

.login-form {
    width: 500px;
    height: 420px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 44px;
    border-radius: 10px;
    box-shadow: 0px 2px 20px  rgba(0, 0, 0, 0.3);
    .login-title {
        font-size: 32px;
        font-weight: 700;
        color: #3C4353;
        text-align: center;
        padding-bottom: 12px;
        font-family: "思源黑体", SiYuanRegular;
    }
    p {
        font-size: 16px;
        margin-bottom: 12px !important;
    }
    .ant-input-affix-wrapper {
        border: none;
        border: 1px solid rgba(211, 211, 211, 1);
    }

    .ant-input {
        background-color: rgba(24, 144, 255, 0)
    }

    .ant-input-affix-wrapper:hover {
        border: 1px solid rgba(211, 211, 211, 1);
    }

    .user-name {
        width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}