@font-face {
  font-family: YouSheBiaoTiHei;
  src: url('./assets/fonts/yousheyh.ttf');
}

@font-face {
  font-family: SiYuanRegular;
  src: url('./assets/fonts/SourceHanSansCN-Regular.ttf');
}



@import "./style/common.scss";
@import "./style/icon/iconfont.css";
@import "./style/login.scss";

body {
  margin: 0;
  padding: 0;
  font-family: SiYuanRegular !important;
}

@media screen and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 1366px) {
  html {
    font-size: 12px;
  }
}

/* 页面刷新动画*/
.route {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: SiYuanRegular;
}

.logo {
  font-family: YouSheBiaoTiHei;
  font-size: 28px;
  color: #fff;
  text-align: center;
  margin-bottom: 6px
}

#root {
  p {
    margin-bottom: 0;
  }

  .search_box_width {
    width: 60%;
  }

  .search-span {
    line-height: 32px !important;
    float: left;
    font-size: 14px;
    font-family: SiYuanRegular;
    color: rgba(102, 102, 102, 1);
  }
}

/* 查看弹窗的公共样式组合 */
.view-modal {
  .ant-col {
    margin-bottom: 16px;

    >span:first-child {
      width: 100px;
      display: inline-block;
      text-align: right;
      color: #838A9D;
      vertical-align: text-top;
    }

    >span:last-child {
      display: inline-block;
      text-align: left;
      color: #3C4353;
      text-overflow: ellipsis;
      width: 64%;
      vertical-align: text-top;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  .ant-table-thead>tr>th {
    border: none;
    background: #F6F7F9;
    color: #3C4353;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }
}

/* 分类标题 */
.category-head {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;

  .head-icon {
    border-radius: 1px;
    background: #1890FF;
    width: 4px;
    height: 14px;
    display: inline-block;
  }

  p {
    display: inline-block;
    margin-left: 6px;
    margin-bottom: 0;
  }
}

//弹窗内数字不自动换行问题修复
.ant-modal-body {
  word-wrap: break-word;
  word-break: break-word;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(221, 226, 238, 1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #f5f5f6;
}

// //全局表头字体样式
// .ant-table-thead>tr>th{
//   font-weight: bold;
// }

// //自定义表格中选择器样式
// .my-selector .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
//   font-weight: bold;
//  }

.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: rgba(230, 247, 255, 1) !important;
}

.ant-select-tree-treenode-checkbox-checked .ant-select-tree-node-content-wrapper {
  background: rgba(230, 247, 255, 1) !important;
}

// 修改禁用框颜色
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input[disabled],
.ant-picker.ant-picker-disabled {
  color: rgba(0, 0, 0, 0.85) !important;
  background-color: #fff !important;
}

.ant-picker-input>input[disabled] {
  color: rgba(0, 0, 0, 0.85) !important;
}

.billing-dot-normal {
  width: 6px;
  height: 6px;
  background: rgba(24, 144, 255, 1);
  border-radius: 6px;
  margin-right: 10px;
}

.billing-dot-gray {
  width: 6px;
  height: 6px;
  background: rgba(137, 151, 176, 1);
  border-radius: 6px;
  margin-right: 10px;
}

.flex-row-ac {
  display: flex;
  align-items: center;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead>tr>th,
.ant-table.ant-table-middle .ant-table-tbody .ant-table-row>td,
.ant-table.ant-table-middle tfoot>tr>th,
.ant-table.ant-table-middle tfoot>tr>td {
  padding: 16px !important;
}

.ant-table-measure-row>td {
  padding: 0 !important;
}