.trigger:hover {
  color: #108ee9;
}

.trigger {
  font-size: 0.8rem;
  line-height: 3.2rem;
  padding: 0 0.8rem;
  cursor: pointer;
  transition: color .3s;
}

.login-form-flex {
  width: 100%;
  margin-top: 1.5rem;
}

.block {
  background: #FFFFFF;
  padding: 0.75rem 0.1rem;
}

.horizontalBetweenFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.horizontalAroundFlex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.horizontalCenterFlex {
  display: flex;
  justify-content: center;
}

.verticalAroundFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.verticalBetweenFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verticalCenterFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontalColumeFlex {
  display: flex;
  align-items: center;
}

.projectSideBar Menu {
  font-size: 2rem;
}

.my-menu .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px !important;
}

.ant-menu-vertical .ant-menu-item {
  margin: 0.25rem !important;
}
.must-input-color{
  color: #ff5d5d !important;
}